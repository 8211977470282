export const getTealiumMyPerfObj = data => {
  if (data) {
    return {
      currentAwardName: data?.currentAward?.Name,
      currentAwardQualificationPeriod: data?.currentAward?.QualifcationPeriod,
      currentLeadershipPercent: data?.currentLeadershipPerc?.BonusPercent,
      totalActiveABOs: data?.totalActiveABOs,
      totalActiveAPCs: data?.totalActiveAPCs,
    };
  }

  return data?.data;
};

export const getTealiumRenewalRemindersObj = data => {
  if (data) {
    return {
      expiredABOSixMonthsGrace: data?.graceABOList,
      expiredAPCOneMonthGrace: data?.graceAPCList,
      future60DaysDatesABOList: data?.futureDatesABOList,
      future60DaysDatesPPList: data?.futureDatesPPList,
      expiredCurrentMonthNoAutoRenewal: data?.curNAList,
      expiredCurrentMonthWithAutoRenewal: data?.curAList,
      loginAda: data?.remindersStatus?.abo,
    };
  }

  return data.data;
};

export const getTealiumWhosCloseObj = data => {
  if (data) {
    return {
      loginAda: data?.abo,
    };
  }

  return data?.data;
};

export const getTealiumDownlineOrderTrackingObj = data => {
  if (data) {
    return {
      loginAda: data?.abo,
    };
  }

  return data?.data;
};


export const getTealiumCouponRemindersObj = data => {
  if (data) {
    return {
      couponListABO: data?.ABOList,
      couponListAPC: data?.APCList,
      loginAda: data?.remindersStatus?.abo,
    };
  }

  return data?.data;
};
