import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

// Page Info
import myPerformanceReducer from "./modules/dashboard/redux/reducers/index";
import renewalReminderReducers from "./modules/renewalReminder/redux/reducers/index";
import whoCloseReducers from "./modules/whosClose/redux/reducers/index";
import downlineOTReducers from "./modules/downlines/redux/reducers/index";
import breakpointReducer from "./utils/breakpoint-reducer";
import couponReminderReducers from "./modules/couponReminder/redux/reducers/index";

import layoutReducer from "./redux/reducers/index";

const store = createStore(
  combineReducers({
    // Page info
    breakpoint: breakpointReducer,
    ...layoutReducer,
    ...myPerformanceReducer,
    ...renewalReminderReducers,
    ...whoCloseReducers,
    ...downlineOTReducers,
    ...couponReminderReducers
  }),
  compose(
    applyMiddleware(thunk),
    (window && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  )
);

export default store;
