// import logo from '../assets/img/logo/AmwayLogo_black.png';
import React from 'react';
import PopupConfirm from '../components/popupConfirm/popupConfirm';
import { Menu } from './index';
import {
  Button,
  Nav,
  Navbar,
} from 'reactstrap';

import { MdMenu, MdClose } from 'react-icons/md';
import bn from '../utils/bemnames';
import { Rectangle } from '../assets/icon/rectangle';
import CheckBox from '../components/checkBox';

const bem = bn.create('header');
//const helper = require('../utils/helper');

class Header extends React.Component {
  state = {
    isOpenUserCardPopover: false,
    isMenuOpen: false,

    user: {},
    showLogout: false,
    userRole: "ABO",
    filterTab: 1,
    showFilterBox: false,
    renewalType: [
      { id: 1, label: 'Eligible for Renewal', checked: false, disabled: false, value: '1' },
      { id: 2, label: 'Late Renewal', checked: false, disabled: false, value: '2' },
      { id: 3, label: 'Auto - Renewal', checked: false, disabled: false, value: '3' },
    ],
    consentStatus:[
      { id: 1, label: 'No Consent Given', checked: false, disabled: false, value: '1' },
      { id: 2, label: 'Consent Given', checked: false, disabled: false, value: '2' },
    ],
    membershipType: [
      { id: 1, label: 'ALL', checked: false, disabled: false, value: '1' },
      { id: 2, label: 'ABO', checked: false, disabled: false, value: '2' },
      { id: 3, label: 'PP', checked: false, disabled: false, value: '3' },
    ],
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userdetails')) || {
      aboNumber: '1234567',
      name: 'Testing Name',
      initial: 'DE',
      title: 'Business Owner',
    };
    this.setState({
      user,
    });
  }

  closeAllPopover = e => {
    console.log(e.target);
    e.preventDefault();
    this.setState(
      {
        isOpenUserCardPopover: false,
      },
      () =>
        document
          .getElementById('root')
          .removeEventListener('click', this.closeAllPopover),
    );
  };

  // getRouter = () => {
  //   if (typeof window === "undefined") 
  //     return {};
  //
  //   return window.useRouter && window.useRouter();
  // };

  handleLogoutPopup = () => {
    this.setState({ showLogout: true });
  }

  toggleUserCardPopover = e => {
    e.preventDefault();
    this.setState(
      {
        isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
      },
      () => {
        if (this.state.isOpenUserCardPopover)
          document
            .getElementById('root')
            .addEventListener('click', this.closeAllPopover);
        else
          document
            .getElementById('root')
            .removeEventListener('click', this.closeAllPopover);
      },
    );
  };

  handleMenu = () => {
    let { isMenuOpen } = this.state;

    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  };

  handleActionLogout = () => {
    this.setState({ showLogout: false });
    this.setState({ isOpenUserCardPopover: false });

    //this.toggleUserCardPopover;
    //this.getRouter().push("/logout");
  };

  handleActionCancel = () => {
    this.setState({ showLogout: false });
  };

  //Clear selected checkbox
  clearChecked = () => {
    let newrenewalType = this.state.renewalType.map(item => {
      return { ...item, checked: false };
    });
    this.setState({ renewalType: newrenewalType});
    this.state.consentStatus.map(item => (item.checked = false));
    this.state.membershipType.map(item => (item.checked = false));
  };

  //Switch Renewal Type Checked Status
  toggleRenewalTypeChecked = id => {
    let newrenewalType = this.state.renewalType?.map(item => {
      if (item?.id === id) {
        return { ...item, checked: !item?.checked };
      }
      return item;
    });
    this.setState({ renewalType: newrenewalType});
  };

  //Switch Consent Checked Status
  toggleConsentStatusChecked = id => {
    let newConsentStatus = this.state.consentStatus?.map(item => {
      if (item?.id === id) {
        return { ...item, checked: !item?.checked };
      }
      return item;
    });
    this.setState({ consentStatus: newConsentStatus});
  };

  //Switch Membership Type Checked Status
  toggleMembershipTypeChecked = id => {
    let newMembershipType = this.state.membershipType?.map(item => {
      if (item?.id === id) {
        return { ...item, checked: !item?.checked };
      }
      return item;
    });
    this.setState({ membershipType: newMembershipType});
  };

  switchFilterTab = tab => {
    this.setState({ filterTab: tab });
  };

  //Click Gray Area to close filter popup and clear filter list
  handelCancel = () => {
    this.setState({ showFilterBox: false });
  };

  filterList = () => {
    this.setState({ showFilterBox: false });
  };

  showFilterBox = () => {
    this.setState({ showFilterBox: true });
  };

  render() {
    const { isMenuOpen } = this.state;
    return (
      <div className={bem.b()}>
        <Navbar light expand className={`amway-header`}>
          <Nav navbar className="hr-menu-toggle">
            <Button 
              id='menu-toggle' 
              onClick={this.handleMenu}
              className="menu-toggle-icon"
              data-tut="menu-icon"
            >
              {!isMenuOpen && <MdMenu size={30} />}
              {isMenuOpen && <MdClose size={30} />}
            </Button>

            {/* <img src={logo} className="amway-logo" alt="" /> */}
            <div className='svg Amway_logo_svg'/>
          </Nav>
        </Navbar>

        {isMenuOpen && <Menu />}

        <PopupConfirm
          title="Log Out"
          showPopup={this.state.showLogout}
          okBtn={{
            text: 'Confirm',
            action: this.handleActionLogout,
          }}
          cancelBtn={{
            text: 'Cancel',
            action: this.handleActionCancel,
          }}
        >
          <span>Log out your current account</span>
        </PopupConfirm>

        {/* Filter View */}
        {this.state.showFilterBox ? (
          <div className={"confirm_container"}>
            <div className={"confirm_backdrop"} onClick={this.handelCancel} />
            <div className={"body_container"}>
              <div className={"rectangle"}>
                <Rectangle />
              </div>
              <div className={"container_title"}>
                <div className={"filter_title"}>Filters</div>
                <div className={"filter_clear"} onClick={this.clearChecked}>
                  Clear All&nbsp;&nbsp;
                  <MdClose size={22} />
                </div>
              </div>
              <div className={"filter"}>
                <div className={"body"}>
                  <ul className={"left"}>
                    <li
                      className={this.state.filterTab === 1 ? "active" : ''}
                      onClick={() => this.switchFilterTab(1)}
                    >
                      Type of Renewal
                    </li>
                    {this.state.userRole !== 'FOA' && (
                      <li
                        className={this.state.filterTab === 2 ? "active" : ''}
                        onClick={() => this.switchFilterTab(2)}
                      >
                        Consent
                      </li>
                    )}
                    {this.state.userRole !== 'FOA' && (
                      <li
                        className={this.state.filterTab === 3 ? "active" : ''}
                        onClick={() => this.switchFilterTab(3)}
                      >
                        Type of Membership
                      </li>
                    )}
                  </ul>
                  <div className={"right"}>
                    {this.state.filterTab === 1 ? (
                      <div>
                        {this.state.renewalType?.map(item => (
                          <div
                            key={item.id}
                            className={item.disabled === true ? "disabledStyle" : ''}
                          >
                            <CheckBox
                              id={item.id}
                              value={item.label}
                              checked={item.checked}
                              onChange={() => this.toggleRenewalTypeChecked(item.id)}
                              disabled={item.disabled}
                              text={item.label}
                            />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.filterTab === 2 ? (
                      <div>
                        {this.state.consentStatus?.map(item => (
                          <div
                            key={item.id}
                            className={item.disabled === true ? "disabledStyle" : ''}
                          >
                            <CheckBox
                              id={item.id}
                              value={item.label}
                              checked={item.checked}
                              onChange={() => this.toggleConsentStatusChecked(item.id)}
                              disabled={item.disabled}
                              text={item.label}
                            />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.userRole !== 'FOA' && this.state.filterTab === 3 ? (
                      <div>
                        {this.state.membershipType?.map(item => (
                          <div
                            key={item.id}
                            className={item.disabled === true ? "disabledStyle" : ''}
                          >
                            <CheckBox
                              id={item.id}
                              value={item.label}
                              checked={item.checked}
                              onChange={() => this.toggleMembershipTypeChecked(item.id)}
                              disabled={item.disabled}
                              text={item.label}
                            />
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={"buttonGroup"}>
                <button className={"btnCancel"} onClick={this.handelCancel}>
                  Cancel
                </button>
                <button className={"btnOK"} onClick={this.filterList}>
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        ) : null}

      </div>
    );
  }
}

export default Header;
