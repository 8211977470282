import axios from '../../../../utils/axios-interceptor';
import { getAPIHost, formatDateYYYYMM } from '../../../../utils/helper';
import { getAccessHeader, getADA, getAll } from '../../../../utils/loginSession';
import { getTealiumWhosCloseObj } from '../../../../utils/commonService';
import { trackWhosClose, PAGES } from '../../../../utils/analytics';

export const enums = {
  SET_WHOSCLOSE_STATE: { type: 'SET_WHOSCLOSE_STATE', state: 'whoscloseData' },
  SET_WHOSCLOSE_DETAIL_STATE: {
    type: 'SET_WHOSCLOSE_DETAIL_STATE',
    state: 'whoscloseDetails',
  },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchState =
  (type, data = {}) =>
    async (dispatch, getState) => {
      try {
        const apiUrl = getAPIHost();
        const login = getAll(getState());
        if (type.url) {
          const res = await axios({
            headers: login.accessHeader,
            method: 'post',
            url: `${apiUrl}/${type.url}`,
            data: data,
          });
          dispatch(setState(type, res.data));
          return res.data;
        } else {
          dispatch(setState(type, data));
        }
      } catch (err) {
        console.log(err);
      }
    };

// Whos Close 
export const fetchWhosCloseState = params => async (dispatch, getState) => {
  console.log("in fetchWhosCloseState whos close...");
  try {
    const apiUrl = getAPIHost();
    console.log("api url");
    console.log(`${apiUrl}/whosClose/whosclose`);
    console.log(getAccessHeader(getState()));
    console.log(params.aff);
    console.log(getADA());
    console.log(formatDateYYYYMM(params.period));
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/whosClose/whosclose`,
      params: {
        aff: params.aff,
        loginAda: getADA(),
        period: formatDateYYYYMM(params.period),//"202208",//formatDateYYYYMM(params.period),
      },
    });
    console.log("whos close res: ");
    console.log(res);
    console.log(res.data.volumeDetailPeriods[0].httpStatus);

    // Calling Tealium event
    let whosClose = getTealiumWhosCloseObj(res?.data?.volumeDetailPeriods[0]?.detail);
    let sortPeriod = formatDateYYYYMM(params.period);
    trackWhosClose(whosClose, sortPeriod, { type: PAGES.whosClose });

    if (res?.data && res?.data?.volumeDetailPeriods[0]?.httpStatus == "200") {
      dispatch(setState(enums.SET_WHOSCLOSE_STATE, { ...res.data }));
      console.log("httpstatus");
      return { ...res.data };
    }
  } catch (err) {
    console.log(err);
  }
};

export const getContentData = async lang => {
  console.log("start getContentData whos close...");
  return await axios.get(getAPIHost() + `/layout/data/${lang}`)
}

export const getWhosCloseLayoutData = async lang => {
  console.log("start whos closelayout ...", getAPIHost() + `/layout/whosclose/${lang}`);
  return await axios.get(getAPIHost() + `/layout/whosclose/${lang}`)
}

// state
const whosCloseState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  whosCloseState,
};
