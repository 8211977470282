import axios from '../../utils/axios-interceptor';
import { getAPIHost } from '../../utils/helper';
import { getAccessHeader } from '../../utils/loginSession';
import translationData from '../../translation.json'

export const enums = {
  SET_LAYOUT: { 
    type: 'SET_LAYOUT',
    state: 'layout'
  },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

// My Performance
export const fetchLayout = () => async (dispatch, getState) => {
  let currentLocale = (localStorage.getItem('language') || "id") + "-" + (localStorage.getItem('country') || "id");
  try {
    const apiUrl = getAPIHost();
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/layout/str/${currentLocale}`,
    });
    console.log("fetchLayout res ", res);
    if (res?.status === 200) {
      if(Object.keys(res.data?.menus).length && Object.keys(res.data?.layout).length){
        dispatch(setState(enums.SET_LAYOUT, { ...res.data }));
        // console.log("JSON stringify", JSON.stringify(res.data));
        return { ...res.data };
      } else {
        dispatch(setState(enums.SET_LAYOUT, { ...translationData }));
        return { ...translationData };
      }
    } else {
        dispatch(setState(enums.SET_LAYOUT, { ...translationData }));
        return { ...translationData };
    }
  } catch (err) {
    console.log(err);
    console.log("Falling back to default translation");
    dispatch(setState(enums.SET_LAYOUT, { ...translationData }));
    return { ...translationData };
  }
};

// state
const layoutState = (state = {'layout': {...translationData}}, action) => {
  switch (action.type) {
    // for special case
    
    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

    // for default
    return state;
  }
};

export default {
  layoutState,
};
