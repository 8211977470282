/**
 *
 * en-id
 *
 * Indonesia's English locale labels
 */

 export const Strings = {
    COUNTRY_INDONESIA: "Indonesia",  
    DASHBOARD_MY_PERFORMANCE: "My Performance",
  };
  