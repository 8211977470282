/**
 *
 * Main locale file for this MSB Business Dashboard. Determines which locale to be used on render.
 *
 * Keys across all locale files must be in sync
 */

import { Strings as en_id } from "./en-id";


let currentLocale = "en-my";

function getLocaleStrings() {
  const getCurrentLocale = () => {
    currentLocale = (localStorage.getItem('language') || "en") + "-" + (localStorage.getItem('country') || "id");

    switch (currentLocale) {
      case "en-id":
        return en_id;
      default:
        return en_id;
    }
  };

  return getCurrentLocale();
}

export default getLocaleStrings();
