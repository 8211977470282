import axios from '../../../../utils/axios-interceptor';
import { getAPIHost } from '../../../../utils/helper';
import { getAccessHeader, getADA } from '../../../../utils/loginSession';
import { isEmpty } from '../../../../utils/helper';
import { getTealiumDownlineOrderTrackingObj } from '../../../../utils/commonService';
import { trackDownlineOrderTracking, PAGES } from '../../../../utils/analytics';

export const enums = {
  SET_DOWNLINE_DETAILS_STATE: {
    type: 'SET_DOWNLINE_DETAILS_STATE',
    state: 'downlineDetails'
  },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

// Downline Order Tracking
export const fetchDownlineDetailsState = params => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/downline/details`,
      params: {
        aff: params.aff,
        loginAda: getADA(),
        period: params.period,
        iterations: params.iterations
      },
    });

    // Calling Tealium event
    let downlineOrderTracking = getTealiumDownlineOrderTrackingObj(res?.data?.aboDownlineData);
    let sortPeriod = params.period;
    trackDownlineOrderTracking(downlineOrderTracking, sortPeriod, { type: PAGES.downlineOrderTracking });

    if (res?.data && res?.data?.downlineOTStatus?.httpStatus === 200) {
      dispatch(setState(enums.SET_DOWNLINE_DETAILS_STATE, { ...res.data }));
      return { ...res.data };
    } else if (res?.data && res?.data?.downlineOTStatus?.httpStatus === 202) {
      return { ...res.data };
    }
  } catch (err) {
    console.log(err);
    if (err?.code === 'ECONNABORTED') {
      return { status: "timeout" };
    }
  }
};

export const getContentData = async lang => {
  return await axios.get(getAPIHost() + `/layout/data/${lang}`)
}

export const getDownlineOTLayoutData = async lang => {
  return await axios.get(getAPIHost() + `/layout/downlineordertracking/${lang}`)
}

// state
const downlinesState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        if (action.type === 'SET_DOWNLINE_DETAILS_STATE') {
          let tempArrState = [];
          if (!isEmpty(state)) {
            tempArrState = [...state[enums[action.type].state]]
          }
          tempArrState.push(action.payload);
          return {
            ...state,
            [enums[action.type].state]: tempArrState,
          }
        }
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  downlinesState,
};
