import axios from '../../../../utils/axios-interceptor';
import { getAPIHost, formatDateYYYYMM } from '../../../../utils/helper';
import { getAccessHeader, getADA, getAll } from '../../../../utils/loginSession';
import { getTealiumRenewalRemindersObj } from '../../../../utils/commonService';
import { trackRenewalReminders, PAGES } from '../../../../utils/analytics';

export const enums = {
  SET_REMINDERS_STATE: { type: 'SET_REMINDERS_STATE', state: 'reminders' },
  SET_REMINDERS_DETAIL_STATE: {
    type: 'SET_REMINDERS_DETAIL_STATE',
    state: 'reminderDetails',
  },
  SET_SEND_CONSENT_EMAIL: { type: 'SET_SEND_CONSENT_EMAIL', state: 'sendConsentEmail' },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchState =
  (type, data = {}) =>
    async (dispatch, getState) => {
      try {
        const apiUrl = getAPIHost();
        const login = getAll(getState());
        if (type.url) {
          const res = await axios({
            headers: login.accessHeader,
            method: 'post',
            url: `${apiUrl}/${type.url}`,
            data: data,
          });
          dispatch(setState(type, res.data));
          return res.data;
        } else {
          dispatch(setState(type, data));
        }
      } catch (err) {
        console.log(err);
      }
    };

// Renewal Reminders
export const fetchRemindersState = params => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/renewalReminders/reminders`,
      params: {
        aff: params.aff,
        loginAda: getADA(),
        period: formatDateYYYYMM(params.period),
      },
    });
    console.log("Tung reminder's response: ", res);

    // Calling Tealium event
    let renewalReminders = getTealiumRenewalRemindersObj(res?.data);
    let sortPeriod = formatDateYYYYMM(params.period);
    trackRenewalReminders(renewalReminders, sortPeriod, { type: PAGES.renewalReminders });

    if (res?.data && res?.data?.remindersStatus?.httpStatus == "200") {
      dispatch(setState(enums.SET_REMINDERS_STATE, { ...res.data }));
      return { ...res.data };
    }
  } catch (err) {
    console.log(err);
  }
};

export const getContentData = async lang => {
  return await axios.get(getAPIHost() + `/layout/data/${lang}`)
}

export const getRenewalRemindersLayoutData = async lang => {
  return await axios.get(getAPIHost() + `/layout/renewalreminders/${lang}`)
}

//Function to send email
export const sendConsentEmail = data => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'POST',
      url: `${apiUrl}/renewalReminders/sendConsentEmail`,
      data: data,
    });
    // console.log("Send Consent Email", res.data);

    return {
      ...res.data,
    };
  } catch (err) {
    console.log(err);
  }
};

// state
const remindersState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  remindersState,
};
